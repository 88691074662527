<script setup></script>

<template>
  <div class="text-center w-full flex items-center justify-center my-8 px-5 lg:px-20">
    <div class="max-w-7xl flex flex-col gap-8">
      <div class="flex flex-col gap-2">
        <p class="text-xs">{{ $t("post_footer_module.phrase_1") }}</p>
        <div class="flex justify-center items-center">
          <img class="max-h-[40px]" src="/img/footer/06_Harmonitzat_REact_EU-marca Next generation_SOC_correu.png" />
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <p class="text-xs">{{ $t("post_footer_module.phrase_2") }}</p>
        <div class="flex justify-center items-center">
          <img class="max-h-[40px]" src="/img/footer/programa-industrial-europeo.jpg" />
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <p class="text-xs">{{ $t("post_footer_module.phrase_3") }}</p>
        <div class="flex justify-center items-center">
          <img class="max-h-[40px]" src="/img/footer/accio.png" />
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <p class="text-xs">{{ $t("post_footer_module.phrase_4") }}</p>
      </div>
      <div class="flex flex-col gap-2">
        <p class="text-xs">{{ $t("post_footer_module.phrase_5") }}</p>
        <div class="flex justify-center items-center">
          <img class="max-h-[40px]" src="/img/footer/arc.jpg" />
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <p class="text-xs">{{ $t("post_footer_module.phrase_6") }}</p>
      </div>
    </div>
  </div>
</template>
